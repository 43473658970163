import React from 'react'
import * as s from './Button.module.css'
export const Button = (props) => {
    return (
        <button  type="submit" className={s.button} onClick={props.onClick}
        // onClick={props.open}
        >
            <span >
                {props.buttonText}
            </span>
        </button>
    )
}
