// extracted by mini-css-extract-plugin
export var wrapper = "ServicesForTablet-module--wrapper--AEcyj";
export var titleModule = "ServicesForTablet-module--titleModule--qrYx5";
export var subtitleModule = "ServicesForTablet-module--subtitleModule--gyW27";
export var headline = "ServicesForTablet-module--headline--baPTh";
export var title = "ServicesForTablet-module--title--uXxuE";
export var Illustation = "ServicesForTablet-module--Illustation--WfCCN";
export var services = "ServicesForTablet-module--services--DcqCa";
export var servicesModule = "ServicesForTablet-module--servicesModule--3ALay";
export var servicesModuleEmpty = "ServicesForTablet-module--servicesModuleEmpty--gG9ou";
export var icon = "ServicesForTablet-module--icon--fom9W";
export var subtitle = "ServicesForTablet-module--subtitle--Ja6Jd";
export var text = "ServicesForTablet-module--text--up7R4";