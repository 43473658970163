// extracted by mini-css-extract-plugin
export var wrapper = "ServicesForDesktop-module--wrapper--WgaTM";
export var headline = "ServicesForDesktop-module--headline--CPX35";
export var container = "ServicesForDesktop-module--container--8fDKQ";
export var content = "ServicesForDesktop-module--content--eU+nJ";
export var title = "ServicesForDesktop-module--title--eyXJN";
export var services = "ServicesForDesktop-module--services--m49BP";
export var servicesModule = "ServicesForDesktop-module--servicesModule--76fhQ";
export var icon = "ServicesForDesktop-module--icon--MmOhp";
export var subtitle = "ServicesForDesktop-module--subtitle--GRjn-";
export var text = "ServicesForDesktop-module--text--zqD2j";
export var Illustation = "ServicesForDesktop-module--Illustation--jLjkk";