import * as React from "react"
import {graphql} from 'gatsby';
import { Index } from "../components/index/Index"
const IndexPage = () => (
<>
    <Index/>
</>
)

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;



 
