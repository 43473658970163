

import React from 'react'
import {ContactForDesktop} from './ContactForDesktop'
import {ContactForTablet} from './/ContactForTablet'
import { useWindowSize } from 'usehooks-ts'


 export const Contact = (props) => {
    const { width } = useWindowSize()
 
    return (
        <div id='servises'>
            { width <= '1024' ? <ContactForTablet fadeInFalse={props.fadeInFalse}/> : <ContactForDesktop fadeInFalse={props.fadeInFalse}/>} 
        </div>
    )
}
