// extracted by mini-css-extract-plugin
export var wrapper = "Header-module--wrapper--OgOsu";
export var mobileMenu = "Header-module--mobileMenu--5BM8J";
export var openMobileMenu = "Header-module--openMobileMenu--t0DWw";
export var closeMobileMenu = "Header-module--closeMobileMenu--e5O5U";
export var navAppear = "Header-module--navAppear--yuw3N";
export var navDisappear = "Header-module--navDisappear--k5vyI";
export var nav2 = "Header-module--nav2--9867-";
export var nav2Element = "Header-module--nav2Element--+7cN5";
export var containerButton = "Header-module--containerButton--7n23g";
export var burgerFirstLine = "Header-module--burgerFirstLine--ZrAls";
export var burgerSecondLine = "Header-module--burgerSecondLine--2ezuz";
export var burgerFirstLineDefault = "Header-module--burgerFirstLineDefault--deaxu";
export var burgerSecondLineDefault = "Header-module--burgerSecondLineDefault--we27e";
export var burgerFirstLineOpen = "Header-module--burgerFirstLineOpen--YoFVM";
export var burgerSecondLineOpen = "Header-module--burgerSecondLineOpen--iLdlP";
export var burgerFirstLineClose = "Header-module--burgerFirstLineClose--08s8v";
export var burgerSecondLineClose = "Header-module--burgerSecondLineClose--DFATp";
export var logo = "Header-module--logo--LQlJi";
export var navbar = "Header-module--navbar--zKdZz";
export var button = "Header-module--button--w8EZ0";
export var buttonLang = "Header-module--buttonLang--+664A";
export var buttonText = "Header-module--buttonText--x+c3-";
export var linbuttonTextkText = "Header-module--linbuttonTextkText--mALck";
export var buttonLangMobileMenu = "Header-module--buttonLangMobileMenu--kAZwU";