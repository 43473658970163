import React from 'react'
import * as s from './Header.module.css'
import logo from '../../../images/logo.svg'
import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import {Trans} from 'gatsby-plugin-react-i18next';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useState, useEffect } from "react";



export const Header = (props) => {
    useEffect(() => {
        window.onresize = myHandlerFunction;
    }, []);

    const { originalPath} = useI18next();

    const [mobileMenuVar,setMobileMenu] = useState()

    const [burgerFirstLineVar,setBurgerFirstLine] = useState()

    const [burgerSecondLineVar,setBurgerSecondLine] = useState()

    const [nav2ElementVar, setNav2ElementVar] = useState()

    const [counter, setCounter] = useState(0)

    const myHandlerFunction = (props) => {
        if(window.innerWidth>700) {
            setBurgerFirstLine(s.burgerSecondLineDefault);
            setBurgerSecondLine(s.burgerSecondLineDefault);
            setMobileMenu(s.closeMobileMenu);
            setNav2ElementVar(s.navDisappear);
            setCounter(0);
        }
    }

    const OpenMobileMenu = (props) => {
        if(counter==0) {
            setBurgerFirstLine(s.burgerFirstLineOpen);
            setBurgerSecondLine(s.burgerSecondLineOpen);
            setMobileMenu(s.openMobileMenu);
            setNav2ElementVar(s.navAppear);
            setCounter(1);
        } else {
            setBurgerFirstLine(s.burgerFirstLineClose);
            setBurgerSecondLine(s.burgerSecondLineClose);
            setMobileMenu(s.closeMobileMenu);
            setNav2ElementVar(s.navDisappear);
            setCounter(0);
        }
    }

    const isGerman = () => {
        if (typeof window !== 'undefined') {
            return window.location.pathname === '/de/'
        }
        return false;
    }

    return (
        <div className={s.wrapper}>
            <Link to='/'>
                <img src={logo} alt="WEmkIT logo" className={s.logo} />
            </Link>
            <div className={s.navbar}>
                {/* <button className={s.button} onClick={() => scrollTo('#about')}>
                    <span className={s.buttonText}>
                        <Trans>header_about</Trans>
                    </span>
                </button> */}
                <button className={s.button}  onClick={() => scrollTo('#servises')}>
                    <span className={s.buttonText}>
                        <Trans>header_services</Trans>
                    </span>
                </button>
                <button className={s.button}  onClick={() => scrollTo('#contact')}>
                    <span className={s.buttonText}>
                        <Trans>header_contacts</Trans>
                    </span>
                </button>
                <div className={s.containerButton} onClick={(event) => OpenMobileMenu(event)} >
                    <div class={`${s.burgerFirstLine} ${burgerFirstLineVar}`}></div>
                    <div class={`${s.burgerSecondLine} ${burgerSecondLineVar}`}></div>
                </div>
                <div className={`${s.mobileMenu} ${mobileMenuVar}`}>
                    <ul id="nav2" class={s.nav2}>
                        <li className={`${s.nav2Element} ${nav2ElementVar}`}  onClick={(event) => {scrollTo('#servises'); OpenMobileMenu(event)}}>
                            <span>
                                <Trans>header_services</Trans>
                            </span>
                        </li>
                        <li className={`${s.nav2Element} ${nav2ElementVar}`} onClick={(event) => {scrollTo('#contact');OpenMobileMenu(event)}}>
                            <span>
                                <Trans>header_contacts</Trans>
                            </span>
                        </li>
                        <li className={`${s.nav2Element} ${nav2ElementVar}`}  onClick={(event) => {scrollTo('#contact');OpenMobileMenu(event)}}>
                        {
                            isGerman() ?
                                <Link  to={originalPath}  language='en'  onClick={props.fadeNone} className={s.buttonLangMobileMenu} state={{displayFadeIn: true}}>
                                    <span className={s.buttonLangMobileMenu}> en</span>
                                </Link>
                             :
                                <Link   to='/' language='de' onClick={props.fadeNone} className={s.buttonLangMobileMenu} >
                                     <span className={s.buttonLangMobileMenu}> de</span>
                                </Link>
                        }
                        </li>

                    </ul>
                </div>
                {
                    isGerman() ?
                        <Link  to={originalPath}  language='en'  onClick={props.fadeNone} className={s.buttonLang} state={{displayFadeIn: true}}>
                            <span className={s.buttonText}> en</span>
                        </Link>
                     :
                        <Link   to='/' language='de' onClick={props.fadeNone} className={s.buttonLang} >
                             <span className={s.buttonText}> de</span>
                        </Link>
                }
            </div>
        </div>
    )
}

