import React from 'react';
import * as s from './ServicesForTablet.module.css'
import { Trans } from 'react-i18next';
import Fade from 'react-reveal/Fade';

export const PartnershipForTablet = (props) => {
  return  (
    <div className={s.wrapper}  id='servises'>
        <div className={s.titleModule} style={props.imgPosition === 'right' ? {flexDirection:'row'} : {flexDirection:'row-reverse'}}>
            <div className={s.subtitleModule}>

            </div>
                <Fade bottom when={props.fadeInFalse}>
                    <div>


                    </div>
            </Fade>
        </div>
        <div className={s.services}>
            <Fade bottom when={props.fadeInFalse}>
                <div className={s.servicesModule}>
                    <img src={require('../../../images/1.svg').default} className={s.icon} alt="" />
                    <div>
                        <h2 className={s.subtitle}><Trans>partnership_title_1</Trans></h2>
                        <p className={s.text}><Trans>partnership_text_1</Trans></p>
                    </div>
                </div>
            </Fade>
            <Fade bottom when={props.fadeInFalse}>
                <div className={s.servicesModule}>
                    <img src={require('../../../images/3.svg').default} className={s.icon} alt="" />
                    <div>
                        <h2 className={s.subtitle}><Trans>partnership_title_2</Trans></h2>
                        <p className={s.text}><Trans>partnership_text_2</Trans></p>
                    </div>
                </div>
            </Fade>
            <Fade bottom when={props.fadeInFalse}>
                <div className={s.servicesModuleEmpty}>

                    <div>

                    </div>
                </div>
            </Fade>
        </div>
    </div>
  )
};
