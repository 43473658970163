import React, { useState} from 'react'
import * as s from './Index.module.css'
import { Hero } from '../screens/hero/Hero'
// import {NewServices} from '../screens/services/ServicesForDesktop'
import { Services } from '../screens/services/Services'
import { ModalWindow } from '../ui/modalWindow/ModalWindow'
// import { About } from '../screens/about/About'
import { Contact } from '../screens/contact/Contact'
// import {TabPanel} from '../screens/tabPanel/TabPanel'
import { Footer } from '../screens/footer/Footer'
import Test from '../ui/Test'


export const Index = () => {
    
    const [windowStyle, setWindowStyle] = useState({display:"none"})


    let displayFadeIn = true;
    
    if (typeof window !== 'undefined') {
        displayFadeIn = localStorage.getItem("displayFadeIn") ? true : undefined;
        localStorage.removeItem("displayFadeIn");
    }


 
    const openWindow = () => {
        setWindowStyle({display:'block'})
    }
    const closeWindow = () => {
        setWindowStyle({display:'none'})
    }
    return (
        <div className={s.wrapper}>
            <ModalWindow windowStyle={windowStyle} close={closeWindow}/>
            <Hero open={openWindow} fadeNone={() => localStorage.setItem("displayFadeIn", true)} fadeInFalse={displayFadeIn}/>
            {/* <Test/> */}
            <Services fadeInFalse={displayFadeIn}/>
            {/* <About/> */}
            {/* <Services open={openWindow}/> */}
            {/* <TabPanel/> */}
            <Contact fadeInFalse={displayFadeIn}/>
            <Footer/>
      
        </div>
    )
}



