import { Link } from 'gatsby'
import React from 'react'
import * as s from './Footer.module.css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFacebookF, faLinkedinIn} from '@fortawesome/free-brands-svg-icons'
import copyright from '../../../images/c.png'

export const Footer = () => {
    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <div className={s.companyInfo}>
                    <Link to='/impressum' className={s.link}>
                        <span> Impressum</span>
                    </Link>
                    <Link style={{fontSize: 15 + 'px', marginRight: 5 + 'px', marginLeft: 5 + 'px'}}>
                    |
                    </Link>
                    <Link to='/datenschutzerklärung' className={s.link}>
                        <span>Datenschutzerklärung</span>
                    </Link>
                </div>
                {/* <div className={s.socialmedia}>
                        <a href="https://www.linkedin.com/in/ilja-sucharev-55589732/">
                        <FontAwesomeIcon icon={faLinkedinIn} className={s.linkedinIcon}/>
                        </a>
                        <a href="https://de-de.facebook.com/">
                        <FontAwesomeIcon icon={faFacebookF} className={s.facebookIcon}/>
                        </a>
                    </div> */}
                    <div className={s.copyrightModule}>
                        <p >Copyright </p>
                        <img src={copyright} className={s.copyright} alt="" />
                        <p >
                            2022 - WEmkIT GmbH
                        </p>
                    </div>
                    
           </div>
        </div>
    )
}
