import React from 'react'
import * as s from './ModalWindow.module.css'
import { Button } from '../button/Button'
import closeButton from '../../../images/close.svg'
export const ModalWindow = (props) => {
    return (
        <div style={props.windowStyle}>
            <div  className={s.container}>
                <button type='button' onClick={props.close}>
                    <img  src={closeButton} className={s.closeButton} alt="" />
                </button>
                <div className={s.contactBlock}>
                        <h2 className={s.headline}>Create your application right now</h2>
                        <input type="text" placeholder='Name' /> 
                        <input type="phone"  placeholder='Phone'/> <br />
                        <input type="email" placeholder='Email adress' /> <br />
                        <textarea type="text" placeholder='Your message'  cols="20" rows="3"/> <br />
                        <div className={s.buttonBlock}> <Button buttonText='submit'/></div>
                </div>
            </div>
            <div className={s.wrapper}></div>
        </div>
    )
}
