import React from 'react'
import {ServicesForDesktop} from './ServicesForDesktop'
import {ServicesForTablet} from './/ServicesForTablet'
import { useWindowSize } from 'usehooks-ts'
import { PartnershipForDesktop } from './PartnershipForDesktop'
import { PartnershipForTablet } from './PartnershipForTablet'


 export const Services = (props) => {
    const { width } = useWindowSize()
   
    return (
        <>
        <div id='servises'>
            { width <= '1024' ? <ServicesForTablet fadeInFalse={props.fadeInFalse} imgPosition='left'/> : 
                 <ServicesForDesktop  imgPosition='left' fadeInFalse={props.fadeInFalse}
            />} 
        </div>
        <div id='servises'>
            { width <= '1024' ? <PartnershipForTablet fadeInFalse={props.fadeInFalse} imgPosition='right'/>  :
                 <PartnershipForDesktop imgPosition='right' fadeInFalse={props.fadeInFalse}
             />} 
        </div>
        </>
    )
}




